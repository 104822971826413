@-webkit-keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@-moz-keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@-ms-keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@-webkit-keyframes slide-up {
  from {
    -moz-transform: translateY(2rem);
    -o-transform: translateY(2rem);
    -ms-transform: translateY(2rem);
    -webkit-transform: translateY(2rem);
    transform: translateY(2rem);
    opacity: 0;
    max-height: 0; }
  to {
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@-moz-keyframes slide-up {
  from {
    -moz-transform: translateY(2rem);
    -o-transform: translateY(2rem);
    -ms-transform: translateY(2rem);
    -webkit-transform: translateY(2rem);
    transform: translateY(2rem);
    opacity: 0;
    max-height: 0; }
  to {
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@-ms-keyframes slide-up {
  from {
    -moz-transform: translateY(2rem);
    -o-transform: translateY(2rem);
    -ms-transform: translateY(2rem);
    -webkit-transform: translateY(2rem);
    transform: translateY(2rem);
    opacity: 0;
    max-height: 0; }
  to {
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes slide-up {
  from {
    -moz-transform: translateY(2rem);
    -o-transform: translateY(2rem);
    -ms-transform: translateY(2rem);
    -webkit-transform: translateY(2rem);
    transform: translateY(2rem);
    opacity: 0;
    max-height: 0; }
  to {
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes backgroundPosition {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@-moz-keyframes backgroundPosition {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@-ms-keyframes backgroundPosition {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@keyframes backgroundPosition {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

.RichText {
  font-family: "Source Sans Pro", sans-serif;
  line-height: 2;
  font-size: 1.25rem;
  /*
    Alignment using classes rather than inline styles
    check out the "formats" option
    */ }
  .RichText h1 {
    font-size: 2em; }
  .RichText h2 {
    font-size: 1.75em; }
  .RichText h3 {
    font-size: 1.5em; }
  .RichText h4 {
    font-size: 1.25em; }
  .RichText h5 {
    font-size: 1em; }
  .RichText h6 {
    font-size: 0.75em; }
  .RichText table {
    border-collapse: collapse; }
  .RichText table th,
  .RichText table td {
    border: 1px solid #dfe6ec;
    padding: 0.4rem; }
  .RichText figure {
    display: table;
    margin: 1rem auto; }
  .RichText figure figcaption {
    color: #3f3f3f;
    display: block;
    margin-top: 0.25rem;
    text-align: center; }
  .RichText hr {
    border-color: #dfe6ec;
    border-style: solid;
    border-width: 1px 0 0 0; }
  .RichText code {
    background-color: #fafafa;
    border-radius: 3px;
    padding: 0.1rem 0.2rem; }
  .RichText p {
    margin-bottom: 1rem; }
  .RichText .mce-content-body:not([dir=rtl]) blockquote {
    border-left: 2px solid #dfe6ec;
    margin-left: 1.5rem;
    padding-left: 1rem; }
  .RichText .mce-content-body[dir=rtl] blockquote {
    border-right: 2px solid #dfe6ec;
    margin-right: 1.5rem;
    padding-right: 1rem; }
  .RichText figure.image {
    display: inline-block;
    border: 1px solid gray;
    margin: 0 2px 0 1px;
    background: #f5f2f0; }
  .RichText figure.align-left {
    float: left; }
  .RichText figure.align-right {
    float: right; }
  .RichText figure.image img {
    margin: 8px 8px 0 8px; }
  .RichText figure.image figcaption {
    margin: 6px 8px 6px 8px;
    text-align: center; }
  .RichText img.align-left {
    float: left; }
  .RichText img.align-right {
    float: right; }
